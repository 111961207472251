
import styles from './Bags.module.css';
import helpers from '../../helpers.js';
import {useState, useContext, useEffect, useRef} from 'react';

import { UserContext } from '../../UserContext.js';

import Module from "../../components/Module.js";
import TextInput from "../../components/TextInput.js";
import Selector from "../../components/Selector";
import Button from "../../components/Button.js";
import DateSelector from '../../components/DateSelector.js';

import BagPopup from './BagPopup.js';


function Bags(props) {
  const {user, setUser} = useContext(UserContext);
  const [searchFilter, setSearchFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [siteFilter, setSiteFilter] = useState("all");
  const [fromFilter, setFromFilter] = useState(formatDateToYYYYMMDD(new Date("01/10/2019")));
  const [toFilter, setToFilter] = useState(formatDateToYYYYMMDD(new Date()));
  const [overlayBag, setOverlayBag] = useState(null);

  const [bags, setBags] = useState([{
    id: "CHA-000"
  }]);
  const [filteredBags, setFilteredBags] = useState(bags);
  const [farmers, setFarmers] = useState([]);

  useEffect(() => {
    // Function to apply all filters to the bags list
    const filterBags = () => {
      let updatedBags = [...bags];
      console.log(updatedBags)

      // Filter by search term
      if (searchFilter) {
        updatedBags = updatedBags.filter(bag => bag.charcode.includes(searchFilter) || bag.batch_id.includes(searchFilter) || bag.status.includes(searchFilter) || bag._site && bag._site.name.includes(searchFilter) || bag._order && bag._order._id.includes(searchFilter) || bag._delivery && bag._delivery.includes(searchFilter) || bag._id.includes(searchFilter) || bag._order && bag._order.products[0]._product.includes(searchFilter) || bag._order && bag._order._user.first_name.includes(searchFilter) || bag._order && bag._order._user.last_name.includes(searchFilter) || bag._order && bag._order._user.business_name.includes(searchFilter));
      }

      // Filter by status
      if (statusFilter !== "all") {
        updatedBags = updatedBags.filter(bag => bag.status === statusFilter);
      }
      console.log(updatedBags[8])


      // Filter by site
      if (siteFilter !== "all") {
        console.log(siteFilter)
        updatedBags = updatedBags.filter(bag => bag._site && bag._site.name === siteFilter);
      }
      console.log(updatedBags[8])


      // Filter by date range
      const fromDate = new Date(fromFilter);
      const toDate = new Date(toFilter);
      console.log(updatedBags) 
      updatedBags = updatedBags.filter(bag => {
        const bagDate = new Date(bag.delivery_date);
        return bagDate >= fromDate && bagDate <= toDate || bag.status === "unassigned" || bag.status === "bagged" || bag.status === "assigned" || bag.status === "applied" || bag.status === "pickedUp" ; //REVIEW
      });
      console.log(updatedBags[8])

      setFilteredBags(updatedBags); //TEMPORARY SOLUION
      console.log(updatedBags)
    };

    filterBags();
  }, [searchFilter, statusFilter, siteFilter, fromFilter, toFilter, bags]);


  const fetchBags = async () => {
    const request = {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      }
    };
    console.log(user.backEndURL);
    let response = await fetch(user.backEndURL + "/bags", request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      // let newFarmers = [];
      // jsonResponse.data.forEach((farmer) => {
      //   let farmerDate = new Date(farmer.delivery_date)
      //   let dateString = farmerDate.getDay() + "/" + farmerDate.getMonth() + "/" + farmerDate.getYear();
      //   let newFarmer = {
      //     id: farmer._id,
      //     status: farmer.status,
      //     deliveryDate: dateString,
      //     pincharBags: farmer.products[0].amount,
      //     pelletcharBags: farmer.products[1].amount,
      //     weight: 2.2,
      //     volume: 8
      //   }
      //   newFarmers.push(newFarmer);
      // })
      
      setBags(jsonResponse.data.bags);
      console.log(jsonResponse.data.bags);
      setFilteredBags(jsonResponse.data.bags);
      setFarmers(jsonResponse.data.farmers)
      console.log(jsonResponse.data.bags)
      console.log("SILENTLssY COMPLETES ITS MISSION, LIKE A NINJA");
    } else {
      console.log("Fetch Subscription Failed...")
    }
  }

  useEffect(() => {
    console.log("Fetching Bags")
   
    fetchBags();
  },[user]) 


  const openBagEditor = (bag) => {
    setOverlayBag(bag);
  }

  const closeBagEditor = (bag) => {
    setOverlayBag(null);
  }
  
  const resetFilters = () => {
    setSearchFilter("");
    setStatusFilter("all");
    setSiteFilter("all");
    setFromFilter(formatDateToYYYYMMDD(new Date("01/10/2019")));
    setToFilter(formatDateToYYYYMMDD(new Date()));
  }

  const returnStatusName = (statusName) => {
    switch(statusName){
        case "pickedUp": 
            return "Picked Up";
        default: 
            return statusName.charAt(0).toUpperCase() + statusName.slice(1)
    }
}

  const renderBags = () => {

    if(filteredBags.length === 0){ //No data placeholder

      return(
        <tr className={styles.bagsRow}>
          <td className={styles.placeholderContainer}><div className={styles.placeholderShape}>No data...</div></td>
          <td className={styles.placeholderContainer}><div className={styles.placeholderShape}/></td>
          <td className={styles.placeholderContainer}><div className={styles.placeholderShape}/></td>
          <td className={styles.placeholderContainer}><div className={styles.placeholderShape}/></td>
          <td className={styles.placeholderContainer}><div className={styles.placeholderShape}/></td>
        </tr>
      )
    }

    return(
      <>
        {
          filteredBags.map((bag, i) => {
            if(i>100){
              return(<></>)
            }
            if(filterAndDisplayBag(bag)){
              // console.log(bag.charcode)
              return(<></>)
            }
            return(
              <>
                <tr className={styles.bagsRow}>
                  <td className={helpers.clx(styles.bagsValue, styles.tableCharcode)}>
                    {bag.charcode}
                  </td>
                  <td className={styles.bagsValue}>
                    {bag.status ? returnStatusName(bag.status) : "n/a"}
                  </td>
                  <td className={helpers.clx(styles.bagsValue, bag._order ? "": styles.greyedOut)}>
                    {bag._order ? bag._order._user.first_name.substring(0,1) + ". " + bag._order._user.last_name : "-" }
                  </td>
                  <td className={helpers.clx(styles.bagsValue, bag.site ? "": styles.greyedOut)}>
                    {bag._site ? bag._site.name : "-"}
                  </td>
                  <td className={helpers.clx(styles.bagsValue, bag._order ? "": styles.greyedOut)}>
                    {bag._order ? bag._order.delivery_date.substring(0,10) : "-"}
                  </td>
                  <td className={styles.editRowIcon} onClick={() => openBagEditor(bag)}>
                    pencil
                  </td>
                </tr>
              </>
            )
          })
        }
      </>
    )
  }


  /*
    const [searchFilter, setSearchFilter] = useState("");
    const [statusFilter, setStatusFilter] = useState("All");
    const [siteFilter, setSiteFilter] = useState("All");
    const [fromFilter, setFromFilter] = useState(null);
    const [toFilter, setToFilter] = useState(null);
  */

  const filterAndDisplayBag = (bag) => {
    if(searchFilter === "" && statusFilter === "All" && siteFilter === "All" && fromFilter === null && toFilter === null){
      return true;
    }
    // alert("NOT")
    return false;
  }



  return (
    <div className={"contentGrid"}>
      {overlayBag !== null ? 
        <BagPopup closePopup={closeBagEditor} bag={overlayBag} farmers={farmers} bags={bags}/>
         :
        null
      }
      <Module name={"Biochar Bags"} spanColumn={24} spanRow={2} height={"100%"}>
          <div className={styles.filtersRow}>
            <div className={helpers.clx(styles.filterContainer, styles.searchContainer)}>
              <TextInput value={searchFilter} onChange={setSearchFilter} name={"Search"} labelStyle={"top"} placeholder={"Enter Keyword"}/>
              <div className={helpers.clx(styles.fontAwesome, styles.searchIcon)}>
                search
              </div>
            </div>
            <div className={helpers.clx(styles.filterContainer, styles.statusContainer)}>
              <Selector name={"Status"} value={statusFilter} labelStyle={"top"} onChange={setStatusFilter} data={[{name: "All", value: "all"}, {name: "Unassigned", value: "unassigned"},{name: "Bagged", value: "bagged"},{ name: "Picked Up", value: "pickedUp"}, {name: "Delivered", value: "delivered"}, {name: "Applied", value: "applied"}]} placeholder={"Site"}/>
            </div>
            <div className={helpers.clx(styles.filterContainer, styles.siteContainer)}>
              <Selector name={"Site"} value={siteFilter} labelStyle={"top"} onChange={setSiteFilter} data={[{name: "All", value: "all"}, {name: "Ahlstrom", value: "ARA"}, {name: "Jenkinson", value: "JNR"}]}/>
            </div> 
            <div className={helpers.clx(styles.filterContainer, styles.fromContainer)}>
              <DateSelector value={fromFilter} onChange={setFromFilter} name={"From"} labelStyle={"top"}/>
            </div>
            <div className={helpers.clx(styles.filterContainer, styles.toContainer)}>
              <DateSelector value={toFilter} onChange={setToFilter} name={"To"} labelStyle={"top"}/>
            </div>
            <div className={styles.filtersReset} onClick={resetFilters}>
              times-circle
            </div>
          </div>
          <table className={styles.bagsTable} >
            <tr className={styles.bagsHeaderRow}>
              <th className={styles.bagsHeader}>
                ID/Charcode
              </th>
              <th className={styles.bagsHeader}>
                Status
              </th>
              <th className={styles.bagsHeader}>
                Farmer
              </th>
              <th className={styles.bagsHeader}>
                Site
              </th>
              <th className={styles.bagsHeader}>
                Delivery Date
              </th>
            </tr>
              { 
                renderBags()
              }     
          </table>
      </Module>
    </div>
  );
}


export default Bags;

function formatDateToYYYYMMDD(date) {
  // Ensure the date is a Date object
  if (!(date instanceof Date)) {
      throw new Error("Invalid date");
  }

  // Extract the year, month, and day from the date
  const year = date.getFullYear();
  let month = date.getMonth() + 1; // Months are zero-based in JavaScript
  let day = date.getDate();

  // Add leading zeros to month and day if needed
  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;

  // Return the formatted date string
  return `${year}-${month}-${day}`;
}