import {Html5QrcodeScanner, Html5Qrcode} from "html5-qrcode";
import { useState, useEffect, useRef, useCallback, useMemo } from "react";

import styles from './QRCodeScanner.module.css'; //styles

import qrIcon from "../assets/images/qr.png";
import collapseIcon from "../assets/images/selectArrowGrey.png"
import collapseIconWhite from "../assets/images/selectArrowWhite.png"

import bbbLogo from "../assets/images/bbbLogo.png";
import bagIcon from "../assets/images/iconShoppingBag.png";
import helpers from "../helpers.js";
import TextInput from "./TextInput.js";


import Button from "./Button.js";


const QRScanner = (props) => {
  const [scannerInitialized, setScannerInitialized] = useState(false)
  const [scanResults, setScanResults] = useState(props.scanResults);
  const [collapseBags, setCollapseBags] = useState(false)
  const [charcodeManualValue, setCharcodeManualValue] = useState("CHA-000")


  // const reader = useRef(null); // Create a ref using useRef

    // Reference for audio element
  const audio = new Audio('../assets/scanned.mp3'); // Ensure the file path is correct
  
  const playSound = () => {
    audio.play().catch((error) => {
      console.error('Error playing audio', error);
    });
  };

  const success = useCallback((result, clearScanner) => {
    playSound();
    if(props.scanResults.length + 1  > props.totalBagsExpected){
      
      return;
    }

    // clearScanner.clear();
    setScannerInitialized(false);
    setScanResults(prevScanResults => [...prevScanResults, result])
    console.log(scanResults);
    props.addCharcode(result.slice(-10))
    // props.updateScannedCharcodes(result, true)
    setCharcodeManualValue("CHA-000")
  }, [props.scanResults])

  
  useEffect(() => {
    setScanResults(props.scanResults);
    console.log("scanResults");
    console.log(props.scanResults);

    console.log(scanResults);
  }, [props.scanResults.slice()])


  const error = (err) => {
    // console.log(err)
  }


  


  useEffect(() => {
    console.log("USEFFC")
    if(!scannerInitialized){
      setScannerInitialized(true);
      let devices;
      let cameraId;
      const getCams = async() => {
         devices = await Html5Qrcode.getCameras();
         if (devices.length === 0) {
          console.log("No cameras found");
          return;
        }
  
        cameraId = devices.length > 1 ? devices[1].id : devices[0].id;
        console.log(cameraId)

        const scanner = new Html5QrcodeScanner("reader", {
          qrbox: {
            width: 270,
            height: 900,
          },
          aspectRatio: "1.0",
          fps: 10,
        },[])
        let videoConstraints = { 
          deviceId: cameraId ? { exact: cameraId } : undefined 
        } 
        console.log(videoConstraints)
        // alert("ONCE")
        console.log("AQUI")
        scanner.render(( result) => {
          success(result, scanner.clear(), videoConstraints);
          playSound();
        })
      }
      getCams();
     



  
    /*



// Override the default camera selection behavior
    scanner.getCameras = function () {
      if (navigator.mediaDevices) {
        // Get the list of all video input devices (cameras)
        return navigator.mediaDevices.enumerateDevices().then((devices) => {
          const cameras = devices.filter(
            (device) => device.kind === "videoinput"
          );

          // Look for a camera that mentions "back" or "rear" in its label
          const backCamera = cameras.find((camera) =>
            camera.label.toLowerCase().includes("back")
          );

          // If a back camera is found, return it; otherwise, return the last camera
          return backCamera ? [backCamera] : [cameras[cameras.length - 1]];
        });
      }




    */



      



      


     


    } else {

    }
    console.log("HERE")
  },[props.scanResults.slice()])
  // console.log(props.scanResults)
  let tooManyBags = (props.scanResults.length   > props.totalBagsExpected);
  console.log(tooManyBags)
  return(
    <div className={styles.container}>
        {/* <div className={styles.logoContainer}>
          <img src={bbbLogo} className={styles.logo}/>
        </div> */}
      {/* <div className={styles.scanResult}>{props.scanResults}</div> */}
      <div className={styles.header}>
        Scan Additional Charcodes
      </div>
      <div>
        {
          scannerInitialized ? <>
                      <img src={qrIcon} className={styles.qrIcon}/>
                      <div className={styles.manualEntryContainer}>
                        {/* <input type="text" className={styles.manualEntryTextContainer}>
                          <span className={styles.manualCharSpan}>
                            {charcodeManualValue.slice(0,1)}
                          </span>
                        </input> */}
                        <TextInput name={""} labelStyle={"top"} value={charcodeManualValue} onChange={setCharcodeManualValue}/>
                        <img onClick={() => {success(charcodeManualValue)}} src={collapseIcon} className={styles.manualEntryIconWhite}/>
                      </div>
          </>
          : null
        }

        <div className={styles.reada} id={"reader"}>
        </div>
      </div>
   
      <div className={styles.bagsNumber} onClick={() => {setCollapseBags(!collapseBags)}}>
        <div className={styles.fontAwesome}>
          <img src={bagIcon} className={styles.bagIcon}/>
        </div> 
        Bags Scanned {" (" + props.scanResults.length + "/" + props.totalBagsExpected+  ")"}
        <img src={collapseIcon} className={helpers.clx(styles.collapseIcon, collapseBags ? styles.collapsedIcon : "")}/>
      </div>
      <div className={helpers.clx(styles.scannedCodes, styles.scannedCodesCollapsed)}>
        {props.scanResults.map((charcodeUrl)=> {
          let charcode = charcodeUrl.substring(charcodeUrl.length - 10);
          return(
            <div className={helpers.clx(styles.charcodeTag, collapseBags ? styles.charcodeTagCollapsed : "")} onClick={() => props.removeCharcode(charcodeUrl)}>{charcode}<div className={styles.backspaceIcon}>backspace</div></div>
          )
        })} 
      </div>
      <div className={styles.buttonContainer}>
        <Button color={"Coal"} disabled={tooManyBags} customStyle={tooManyBags ? {"opacity": "0.6"} : {}} name="Back" onPress={() => props.closeScanner()}/>
      </div>
      
    </div>
  )
}

export default QRScanner;





// import React, { useRef, useEffect, useState } from 'react';
// import jsQR from 'jsqr';

// const QRScanner = () => {
//   const videoRef = useRef(null);
//   const canvasRef = useRef(null);
//   const [scannedResult, setScannedResult] = useState(null);

//   useEffect(() => {
//     const video = videoRef.current;
//     const canvas = canvasRef.current;
//     const context = canvas.getContext('2d');

//     const constraints = {
//       audio: false,
//       video: { facingMode: 'environment' } // Use rear camera if available
//     };

//     const handleSuccess = (stream) => {
//       video.srcObject = stream;
//       video.onloadedmetadata = () => {
//         video.play().catch((err) => console.error('Error playing video:', err));
//       };
//       requestAnimationFrame(scanFrame);
//     };

//     const scanFrame = () => {
//       if (video.readyState === video.HAVE_ENOUGH_DATA) {
//         canvas.height = video.videoHeight;
//         canvas.width = video.videoWidth;
//         context.drawImage(video, 0, 0, canvas.width, canvas.height);
//         const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
//         const code = jsQR(imageData.data, imageData.width, imageData.height);
//         if (code) {
//           alert(code.data)
//           setScannedResult(code.data);
//         }
//       }
//       requestAnimationFrame(scanFrame);
//     };

//     const handleError = (err) => {
//       console.error('Error accessing camera:', err);
//     };

//     navigator.mediaDevices.getUserMedia(constraints).then(handleSuccess).catch(handleError);

//     return () => {
//       if (video.srcObject) {
//         alert("Hello")
//         video.srcObject.getTracks().forEach((track) => track.stop());
//       }
//     };
//   }, []);

//   return (
//     <div style={{"width": "200px", "height": "200px", "zIndex": "99999999", "background": "pink"}}>
//       h{scannedResult}a
//       <video ref={videoRef} style={{ display: 'none' }} />
//       <canvas ref={canvasRef} style={{ display: 'none' }} />
//       {scannedResult && <p>Scanned QR code: {scannedResult}</p>}
//     </div>
//   );
// };

// export default QRScanner;


/*
import React, { useState } from 'react';
import { QrReader } from '@cmdnio/react-qr-reader';

const QRScanner = () => {
  const [data, setData] = useState('No result');

  return (
    <>
      <QrReader
        constraints={{
          aspectRatio: "1",
          facingMode: "environment"
        }}
        scanDelay={250}
        onResult={(result, error) => {
          if (!!result) {
            console.log(result.getText())
          }
        }}
        ViewFinder={() => (
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              zIndex: 100000000
            }}
          >overlay</div>
        )}
        videoContainerStyle={{}}
        videoStyle={{}}
      />
      <p>A{data}A</p>
    </>
  );
};

export default QRScanner;
*/